.timeline-content {
  display: flex;
  align-items: center;
}

.timeline-icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

/* The actual timeline (the vertical ruler) */
.main-timeline {
  position: relative;
  padding: 20px 0; /* Add padding for better spacing */
}

/* The actual timeline (the vertical ruler) */
.main-timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #1e99f1; /* Initial color */
  top: 0;
  left: 50%;
  margin-left: -3px;
  transition: background-color 0.5s ease, height 0.5s ease; /* Transition for smooth color and height change */
  height: 100%; /* Initial height */
}

.main-timeline.scrolled::after {
  background-color: #0b5ed7; /* Color after scrolling */
  height: 100%; /* Final height */
}

/* Container around content */
.timeline {
  position: relative;
  background-color: inherit;
  width: 50%;
  padding: 0 20px; /* Add padding for better spacing */
}

/* The circles on the timeline */
.timeline::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #1e99f1;
  border: 5px solid #1e99f1;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.left::after {
  right: -13px;
}

.right::after {
  left: -13px;
}

/* Place the container to the left */
.left {
  padding: 0px 40px 20px 0px;
  left: 0;
}

/* Place the container to the right */
.right {
  padding: 0px 0px 20px 40px;
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  right: 30px;
  border: medium solid #1e99f1;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #1e99f1; /* Adjusted color */
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  left: 30px;
  border: medium solid #1e99f1;
  border-width: 10px 10px 10px 0;
  border-color: transparent #1e99f1 transparent transparent; /* Adjusted color */
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -12px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Adjust timeline position */
  .main-timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .timeline {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Adjust arrow direction */
  .timeline::before {
    left: 60px;
    border: medium solid #1e99f1;
    border-width: 10px 10px 10px 0;
    border-color: transparent #1e99f1 transparent transparent;
  }

  .timeline::after {
    left: 60px;
    border: medium solid #1e99f1;
    border-width: 10px 10px 10px 0;
    border-color: transparent #1e99f1 transparent transparent;
  }

  /* Adjust circle position */
  .left::after,
  .right::after {
    left: 18px;
  }

  /* Adjust arrow direction */
  .left::before {
    right: auto;
  }

  /* Adjust right container */
  .right {
    left: 0%;
  }
}

/* Add a class for card width */
.card-width {
  width: auto;
  max-width: 200%; /* Adjust this value as needed */
  margin: 0 auto;
  transition: transform 0.3s ease; 
  border-color: white;
}

.card-width:hover {
  /* transform: translate3d(0, -15px, 0); 
  cursor: pointer;
  border-color: #4793AF;
  box-shadow: 0 0 10px #4793AF; */
}


@media screen and (min-width: 601px){
  .left::after{
    Right: -13px;
  }
  .right::after {
    
    left: -13px;
  }
}