.partner-card {
    width: 250px; /* Set the width of the card */
    height: 250px; /* Set the height of the card */
    border-radius: 30px; /* Square shape with slight rounded corners */
    background: rgba(255, 255, 255, 0.07); /* Glass effect background */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    backdrop-filter: blur(2px); /* Blur for glass effect */
    -webkit-backdrop-filter: blur(6px); /* Webkit specific for Safari */
    border: 1px solid rgba(255, 255, 255, 0.3); /* Light border */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
    overflow: hidden;
    margin: 0 auto;
  }
  

  
  .partner-image-wrapper {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .partner-image {
    width: 80%; /* Adjust the width to fit the card, 80% of the card's width */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure the image fits within its container without being cropped */
    border-radius: 0px; /* Slight rounding for consistency with the card */
    margin-top: 30px;
    margin-bottom: 30px;
  }


  
  .partner-name {
    color: #fff;
    font-size: 1.2rem;
  }
  
  .partner-role {
    color: #1e99f1;
    text-align: center;
    font-size: 1rem;
    margin-top: 10px;
  }
  