@media (max-width: 600px) {
    .logo-container {
      margin-left: 20px; /* Adjust the margin-left as needed */
    }
  }
  
  @media (max-width: 600px) {
    #headerP {
      font-size: 1.6rem; /* Adjust the font size as needed */
    }
  }
  
  .navbar-blur {
    background: rgba(1, 14, 43, 0.4);
      /* background: "rgba(1, 14, 43, 0.4)",
          backdropFilter: "blur(10px)" */
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); /* Support for Safari */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }
  
  @media (max-width: 765px) {
    .image-container {
       /* Adjust the left margin as needed */
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 145px;
      
    }
    .custom-list{
      margin-left: -30px;
    }
    .contactus{
      position: relative;
      top: 40px;
      
    }
    
  }
#socialnote{
  margin-top: 10px;
}
  @media (max-width: 400px) {
    .image-container {
     /* Adjust the left margin as needed */
     position: absolute;
     left: 50%;
     transform: translateX(-50%);
     top: 145px;
    }
   
  }

.social{
  margin-right: 650px;
}

@media (max-width: 700px) {
  .social{
    margin-right: 0px;

  }
  
  
}



@media (max-width: 600px) {
  .mobile-font-size {
      font-size: 45px !important; /* Adjust this value as needed */
  }
  .c1{
    margin-left: 22px;
  }
  
}

@media (max-width: 400px) {
  .mobile-font-size {
      font-size: 2.5rem !important; /* Adjust this value as needed */
  }
  .c1{
    margin-left: 22px;
  }
  
}

@media (min-width: 601px) and (max-width:1100px) {
  .mobile-font-size {
      font-size: 24px !important; /* Adjust this value as needed */
  }
  .gallerycard{
    width: '10px' !important;
  }

  .mobile-font-size1{
    font-size: 20px !important;
  }
  
  
}
/* App.css */

/* Ensure this rule applies only in responsive mode */
@media (max-width: 600px) {
  .navbar-toggler {
    margin-right: 20px;
  }
  .nav-link {
    margin-bottom: -5px;
    margin-left: 18px; /* Adjust the margin as needed */
    font-size: 12px !important;
    
  }

  
  #preg{
    margin-top: 15px;
  }
  #px{
    margin-top: 15px;
  }
}



@media (max-width: 400px){
  .c1{
    margin-left: 25px;
  }
  .navbar-toggler {
    margin-right: 20px; /* Adjust the margin as needed */
  }
  #preg{
    margin-top: 15px;
  }

  #px{
    margin-top: 15px;
  }
}


@media (min-width: 601px) {
  .navbar-toggler {
    margin-right: 30px; /* Adjust the margin as needed */
  }
  .nav-link {
    margin-left: 30px; /* Adjust the margin as needed */
  }
  .logo-container {
    margin-left: 20px; /* Adjust the margin-left as needed */
  }
}

.custom-footer {
  min-height: 400px; /* Adjust this value as needed */
}
@media (max-width: 1200px) {
  .links-column {
    position: static !important;
    text-align: center !important;
    margin-top: 70px;
   
  }
  #hlink{
    margin-left: 21px;
  }
  #l1{
    margin-left: 20px;
  }

  #l7{
    margin-left: 23px;
  }

  .transition-button{
    margin-left: 10px;
  }
  
}

@media (min-width: 1200px) {
  .links-column {
    position: absolute !important;
    top: 0 !important;
    right: 0px !important;
    text-align: right !important;
  }
}

@media (max-width: 600px) {
  #competition,
  #details {
    display: inline;
  }

  .transition-button{
    margin-left: -10px;
  }


}


@media (max-width: 700px) {
  .carousel1 img{
    width: 90% !important;
  }
}


/* Add this to your App.css */

/* Add this to your App.css */

/* Add this to your App.css */

@keyframes popup {
  0%, 50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.transition-button {
  animation: popup 1s infinite; /* Adjust the animation duration to 2s and remove the ease-out effect */
}


/* Add this to your App.css */

.glow-text {
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #1e90ff, 0 0 70px #1e90ff, 0 0 80px #1e90ff, 0 0 100px #1e90ff, 0 0 150px #1e90ff;
}

@media (max-width: 600px){
  .row-mobile{
    position: relative;
     /* Ensure it has a position value (relative, absolute, fixed) */
    left: -2.1%; /* Move the left edge to the center of the container */
    
    
  }
}

@media (max-width: 400px){
  .row-mobile{
    position: relative;
     /* Ensure it has a position value (relative, absolute, fixed) */
    left: -2.7%; /* Move the left edge to the center of the container */
    
    
  }
}
/* @media (max-width: 400px){
  .row-mobile{
    margin-right: 1.7% !important;
  }
} */



@media (min-width: 900px){
  .card-125k {
    width: 95% !important; /* Adjust the width to make it slightly larger */
    margin: auto;
    

  }
  
  .card-25k {
    width: 70% !important; /* Adjust the width to make it slightly larger */
    margin: auto;
    top:40px;
  }
}



.mobile-font-size {
  /* Adjust the color and intensity as needed */
  text-shadow: 0 0 18px rgba(0, 123, 255, 0.7);
}

@keyframes bounce {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(0.9);
  }
}

/* .card-125k, .card-25k {
  animation: bounce 1s ease-in-out infinite;
} */
@media (min-width:990px) and (max-width: 1200px){
  .navMar{
    margin-right: 40px !important;
  }
}

@media (max-width: 1200px){
  .images{
    margin-top: 10px !important;
    width: 40% !important;
  }
}