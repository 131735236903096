.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 1000;
  cursor: pointer;
}

.glass-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), inset 0 -1px 4px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  transition: background 0.3s, transform 0.3s;
}

.glass-circle:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.1);
}

.scroll-icon {
  width: 24px; /* Adjusted for better fit within the circle */
  height: 24px; /* Adjusted for better fit within the circle */
  color: #ffffff;
  transition: color 0.3s ease;
}

.scroll-icon:hover {
  color: #1e90ff; /* Light blue hover color */
}
