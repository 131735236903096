@media (max-width: 768px) {
    .custom-card {
      width: 100%;
    }
  }
  
  @media (max-width: 400px){
    .row1{
        margin-right: -28px;
    }
  }

  @media(min-width:401px) and (max-width: 700px){
    .row1{
        margin-right: -28px;

  
    }

    .custom-card{
      margin-left: 20px !important;
    }
  }


  .carousel-indicators {
    bottom: -30px; /* Adjust as needed to position below the card */
  }

  @media (min-width: 752px) and (max-width: 1125px) {
    .responsive-font-size {
      font-size: 13px; /* Adjust this value to your desired font size */
    }
    .responsive-font-size1 {
      font-size: 18px; /* Adjust this value to your desired font size */
    }
    
  }

  @media  (max-width: 600px) {
    .responsive-font-size {
      font-size: 12px; /* Adjust this value to your desired font size */
    }
    .responsive-font-size1 {
      font-size: 18px; /* Adjust this value to your desired font size */
    }

    /* .slick-prev{
      left:-10px !important;
    } */

    .slick-next{
      right:-2% !important;
    }
    
  }
  @media  (max-width: 400px){
    .responsive-font-size2{
      font-size: 19px;
    }

    /* .slick-prev{
      left:-5px !important;
    } */

    .slick-next{
      right:-2% !important;
    }
  }

  /* styles1.css */
.slide {
  padding: 0 10px;
   /* Adjust this value for the desired margin */
}

.slick-slide {
  display: flex;
  justify-content: center;
}

@media (max-width:1100px) {
  .mobile-circle {
    margin-top: 10px; /* Adjust the top margin as needed */
  }
}

/* styles1.css */


@media (max-width:400px){
  .custom-card{
    margin-left: 17px !important;
  }
}

/* Override styles for Slick carousel arrows */
/* Override styles for Slick carousel arrows */
/* Override styles for Slick carousel arrows */
.slick-prev:before,
.slick-next:before {
    content: ""; /* Clear the default content */
    display: inline-block;
    width: 48px; /* Adjust width as needed */
    height: 48px; /* Adjust height as needed */
    background-repeat: no-repeat;
}

.slick-prev:before {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" /></svg>'); 
    /* SVG code for left chevron icon */
     /* Rotate left chevron icon */
     margin-left: -8px !important;
}

.slick-next:before {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" /></svg>'); /* SVG code for right chevron icon */
}


@media (min-width:601px) and (max-width: 1050px) {
 

  /* .slick-prev{
    left:-10px !important;
  } */

  .slick-next{
    right:0.2% !important;
  }
  
}

@media (min-width:1051px) and (max-width: 1200px) {
 

  /* .slick-prev{
    left:-10px !important;
  } */

  .slick-next{
    right:-1% !important;
  }
  .slick-prev{
    left:-1% !important;
  }
  
}
