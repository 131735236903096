/* /* Header.css */

@media (min-width: 1000px) {
  .rightC {
    margin-left: 25px;
  }

  .leftC {
    margin-left: -40px;
  }
}

.leftC {
  margin-top: -120px;

}

@media (max-width: 600px) {
  .leftC {
    order: 1; /* Ensure the left content (motion div) appears on top */
    margin-top: -70px;
  }
  .rightC {
    order: 2; /* Ensure the right content (text) appears below the motion div */
  }
}
