html {
  overflow-x: hidden; /* Prevent horizontal overflow */
}
@font-face {
	font-family: 'proxima_nova_rgregular';
	src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot');
	src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
		 url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff') format('woff'),
		 url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf') format('truetype'),
		 url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'proxima_nova_rgbold';
	src: url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot');
	src: url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
		 url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.woff') format('woff'),
		 url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.ttf') format('truetype'),
		 url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.svg#proxima_nova_rgbold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'adelle_rgregular';
	src: url('https://litmus.com/fonts/Emails/adelle_reg-webfont.eot');
	src: url('https://litmus.com/fonts/Emails/adelle_reg-webfont.eot?#iefix') format('embedded-opentype'),
		 url('https://litmus.com/fonts/Emails/adelle_reg-webfont.woff') format('woff'),
		 url('https://litmus.com/fonts/Emails/adelle_reg-webfont.ttf') format('truetype'),
		 url('https://litmus.com/fonts/Emails/adelle_reg-webfont.svg#adelle_rgregular') format('svg');
	font-weight: normal;
	font-style: normal;
}
body{
	font-family: 'proxima_nova_rgregular', Helvetica, Arial, sans-serif;
}
h1{
   font-family: 'proxima_nova_rgbold', Helvetica, Arial, sans-serif;
}
body {
  /* Adjust the path to your background image if necessary */
  background-repeat: round;
  background-size: 800px 800px;
  background-attachment: fixed;
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal overflow */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-family: 'proxima_nova_rgbold', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.card {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 576px) {
  .card {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 768px) {
  .card {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 992px) {
  .card {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 1200px) {
  .card {
    margin-left: 10px;
    margin-right: 10px;
  }
}




/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
